import "./style/App.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Nav from "./components/Nav/Navigation";
import Home from "./components/home/Home";
import Courses from "./components/courses/Courses";
import Contact from "./components/contact/Contact";
import Play from "./components/play/Play";
import Products from "./components/products/Products";
import E404 from "./components/E404/E404";
function App() {
  return (
    <div>
      <BrowserRouter>
        <Nav />
        <Switch>
          <Route path="/products" component={Products} />
          <Route exact={true} path="/contact" component={Contact} />
          <Route path="/courses" component={Courses} />
          <Route path="/play-ground" component={Play} />
          <Route exact={true} path="/" component={Home} />
          <Route component={E404} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
