import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../style/components/E404.css";
import e404 from "../../asset/404.png";
export default function E404() {
  useEffect(() => {
    document.title = "404";
  }, []);
  return (
    <div id="e404">
      <div className="container-fluid">
        <div className="container text-center">
          <div className="row" style={{ marginTop: " 10vh" }}>
            <div className="col-1"></div>
            <div className="col-10" style={{ height: "20%" }}>
              <h1 style={{ color: "black" }}>
                Page Not Found (
                <span style={{ color: "red" }}>{window.location.pathname}</span>
                )
              </h1>
              <br />
              <img src={e404} className="img-fluid" alt="page not found" />
              <br />
              <br />
              <h1>
                back to <Link to="/">Home</Link>
              </h1>
            </div>
            <div className="col-1"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
