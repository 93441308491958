import React from "react";
import "../../../style/components/Content.css";
import looking from "../../../asset/looking.png";
import seeting from "../../../asset/seeting.png";
import webing from "../../../asset/web.png";
import classy2 from "../../../asset/classy.png";
import classy from "../../../asset/freelanser classy1.png";
import apping from "../../../asset/app.png";
import Footer from "../../footer/Footer";
export default function Content() {
  return (
    <div className="container-fluid" id="ifull1">
      <div className="text-center">
        <img
          id="bit-look"
          src={looking}
          className="mx-auto d-block"
          alt="looking"
        />
      </div>
      <hr id="hr1look" />
      <div className="container text-center" id="ifull2">
        <h1 id="welcomem">Welcome !! , Nice To See You Here</h1>
        <div className="container" id="ifull3">
          <img
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            src={seeting}
            id="seeting"
            alt="seting"
            className="img-fluid"
          />
          <h1>Who AM I</h1>
          <p id="welcomep">
            I'm A Software Engineer Student At{" "}
            <a
              href="http://koyauniversity.org/"
              rel="noreferrer"
              target="_blank"
            >
              Koya University
            </a>{" "}
            At Secondt stage , I Live in koya city near Erbil , I try to create
            Classy designs for Websites and to create Usefull and nice programs.{" "}
            <br />
            You can Contact me at{" "}
            <a href="tel:+964-750-755-6391">+(964) 750 755 6391</a> or the other
            Social media below.
          </p>
        </div>
        <div className="container">
          <img
            data-aos="zoom-in-up"
            src={classy}
            className="img-fluid"
            alt="me and panguin"
            style={{ margin: "0px", padding: "0vw 10vw" }}
          />
        </div>
        <hr id="fhr" style={{ marginBottom: " 90px" }} />
        <div className="row">
          <div
            data-aos="fade-up"
            className="col-xl-4 col-lg-4 col-md-12 px-2 col-sm-12 connn"
          >
            <img src={webing} className="img-fluid" alt="web apps" /> <br />
            Creating Websites with React , Vue , Node , Bootstrap ...etc with a
            database of SQL or NoSQL.
          </div>
          <div
            data-aos="fade-up"
            className="col-xl-4 col-lg-4 col-md-12 px-2 col-sm-12 connn"
          >
            <img src={classy2} className="img-fluid" alt="classy desins" />
            <br />
            Classy and Simple Designs.
          </div>
          <div
            data-aos="fade-up"
            className="col-xl-4 col-lg-4 col-md-12 px-2 col-sm-12 connn"
          >
            <img src={apping} className="img-fluid" alt="mobile/pc apps" />
            <br />
            Creating classy apps with{" "}
            <a
              href="https://www.electronjs.org/"
              rel="noreferrer"
              target="_blank"
            >
              Electron
            </a>
            .
          </div>
        </div>
        <h3 style={{marginBottom:"20px"}}>my knowledge & Skills</h3>
        <table style={{ width:"100%" , marginBottom:"20px"}}>
          <tbody>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>Learning:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>🌟🌟🌟🌟🌟</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>Problem Solving:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>🌟🌟🌟🌟🌟</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>SQL:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐⭐⭐⭐</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>NoSQL:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐⭐⭐⭐</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" ,paddingRight:"5%" , textAlign:"right"}}><h5>Java:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐⭐⭐⭐</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%" , textAlign:"right"}}><h5>Java Script:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐⭐⭐⭐</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>React:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐⭐⭐⭐</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>PHP:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐⭐⭐⭐</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>CSS:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐⭐⭐⭐</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>Laravel:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐⭐⭐</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>Electron:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐⭐⭐</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>Node:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐⭐⭐</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>Vue:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐⭐</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>Flutter:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐⭐</h5></td>
          </tr>
          <tr>
            <td style={{width:"50%" , paddingRight:"5%", textAlign:"right"}}><h5>Python:</h5></td>
            <td style={{width:"50%" , textAlign:"left"}}><h5>⭐</h5></td>
          </tr>
          </tbody>
        </table>
        
      </div>
      <Footer />
    </div>
  );
}
