import React, { Component } from "react";
import Face from "./Parts/Face";
import Content from "./Parts/Content";
export default class Home extends Component {
  componentDidMount() {
    document.title = "Kodo Yousif";
  }
  render() {
    return (
      <div>
        <Face />
        <Content />
      </div>
    );
  }
}
