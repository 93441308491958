import { useState, useEffect } from "react";
import "../../style/components/wrk.css";
import Background from "../container/Background";
import Glass from "../container/Glass";
import firebase from "../../database/firebase";
import { Link, Switch, Route } from "react-router-dom";
import Playep from "./Playep";
export default function Products() {
  let [courses, set_sourses] = useState([]);

  useEffect(() => {
    document.title = "Products";
    firebase
      .firestore()
      .collection("products")
      .orderBy("order", "desc")
      .get()
      .then((r) => {
        let all = r.docs.map((r) => {
          return { ...r.data() };
        });
        set_sourses(all);
      });
  }, []);
  function items() {
    return courses.map((r, i) => {
      return (
        <div key={i}>
          <Link to={"/products/" + r.order}>
            <center>
              <img src={r.image} alt="wena" />
              <span>{r.title}</span>
            </center>
          </Link>
        </div>
      );
    });
  }
  return (
    <div>
      <Background>
        <Switch>
          <Route exact={true} path="/products/:order" component={Playep} />
        </Switch>

        {window.location.pathname === "/products" ? (
          <Glass>{items()}</Glass>
        ) : null}
      </Background>
    </div>
  );
}
