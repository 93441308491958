import firebase from "firebase";
import "firebase/analytics";

var firebaseConfig = {
  apiKey: "AIzaSyDwK5uYViVU4pV0g4ut3TYApQDnFFfLL-Y",
  authDomain: "my-website-9419d.firebaseapp.com",
  projectId: "my-website-9419d",
  storageBucket: "my-website-9419d.appspot.com",
  messagingSenderId: "646636616479",
  appId: "1:646636616479:web:10bf74cf3a9b9921154e2a",
  measurementId: "G-B3XBMHYMSE",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
