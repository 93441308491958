import { useState, useEffect } from "react";
import "../../style/components/wrk.css";
import Background from "../container/Background";
import Glass from "../container/Glass";
import firebase from "../../database/firebase";
import { Link, Switch, Route } from "react-router-dom";
import Playep from "./Playep";
import ShowC from "./ShowC";
export default function Courses() {
  let [courses, set_sourses] = useState([]);
  useEffect(() => {
    document.title = "Courses";
    firebase
      .firestore()
      .collection("Courses")
      .orderBy("order", "desc")
      .get()
      .then((r) => {
        let all = r.docs.map((r) => {
          return { ...r.data() };
        });
        set_sourses(all);
      });
  }, []);
  function items() {
    return courses.map((r, i) => {
      return (
        <div key={i}>
          <Link to={"/courses/" + r.collection}>
            <center>
              <img src={r.image} alt="wena" />
              <span>{r.name}</span>
            </center>
          </Link>
        </div>
      );
    });
  }
  return (
    <div>
      <Background>
        <Switch>
          <Route exact={true} path="/courses/:name" component={ShowC} />
          <Route exact={true} path="/courses/:name/:order" component={Playep} />
        </Switch>

        {window.location.pathname === "/courses" ? (
          <Glass>{items()}</Glass>
        ) : null}
      </Background>
    </div>
  );
}
