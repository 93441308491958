import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import firebase from "../../database/firebase";
import Present from "../container/Present";
import "../../style/components/playep.css";
export default function ShowC() {
  let { order, name } = useParams();
  const [alli, set_all] = useState({});
  useEffect(() => {
    document.title = name + "  #" + order;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    order = parseInt(order);
    firebase
      .firestore()
      .collection(name)
      .orderBy("order")
      .get()
      .then((r) => {
        let all = r.docs.map((r) => {
          return { ...r.data() };
        });
        return all;
      })
      .then((r) => {
        for (let g = 0; g < r.length; g++) {
          if (order === r[g].order) {
            set_all({ last: r.length, ep: r[g].link });
            break;
          }
        }
      });
  }, [order]);
  return (
    <Present>
      <center>
        <iframe
          id="filming"
          title="course"
          src={alli.ep}
          frameBorder="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        ></iframe>
        <div id="xware">
          <button>
            <Link
              to={order === "0" ? "/" : "/courses/" + name + "/" + (order - 1)}
            >
              {order === "0" ? "home" : "prev"}
            </Link>
          </button>
          <h4>
            {name} #{order}
          </h4>
          <button>
            <Link
              to={
                order === alli.last - 1
                  ? "/"
                  : "/courses/" + name + "/" + (1 + parseInt(order))
              }
            >
              {order === alli.last - 1 ? "home" : "next"}
            </Link>
          </button>
        </div>
      </center>
    </Present>
  );
}
