import { useRef, useEffect, useState } from "react";
import "../../style/components/Mobile.css";
import bookmark from "../../asset/bookmark.png";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import firebase from "../../database/firebase";
import { NavLink } from "react-router-dom";
export default function Mobile() {
  const nav = useRef(0);
  const all = useRef(0);
  const all1 = useRef(0);
  const all2 = useRef(0);
  const all3 = useRef(0);
  const all4 = useRef(0);
  const all5 = useRef(0);
  const all6 = useRef(0);
  const all7 = useRef(0);
  const all8 = useRef(0);
  const menu = useRef(0);
  const al = [all, all1, all2, all3, all4, all5, all6, all7, all8];
  let openednav = false;

  let [new_course, set_new_course] = useState({});
  let [new_playground, set_new_playground] = useState("");
  let [new_product, set_new_product] = useState("");

  useScrollPosition(({ prevPos, currPos }) => {
    if (prevPos.y > currPos.y) {
      closing();
      nav.current.style.top = "-100px";
    } else {
      nav.current.style.top = "-55px";
    }
  });

  function clicked(e) {
    if (openednav) {
      closing();
    } else {
      opening();
    }
    openednav = !openednav;
  }

  function closing() {
    for (let i = 1; i < al.length; i++) {
      al[i].current.style.setProperty(
        "background",
        "linear-gradient(to right, #ffffff 2px, #ffffff 2px)"
      );
      al[i].current.style.setProperty("color", "white");
    }
    document
      .getElementsByClassName("newest")[0]
      .style.setProperty("color", "white");
    document
      .getElementsByClassName("newest")[1]
      .style.setProperty("color", "white");
    document
      .getElementsByClassName("newest")[2]
      .style.setProperty("color", "white");
    all3.current.style.setProperty("color", "white", "");
    all5.current.style.setProperty("color", "white", "");
    all7.current.style.setProperty("color", "white", "");
    all3.current.style.setProperty(
      "background",
      "linear-gradient(to right, #ffffff 2px, #ffffff 2px)",
      ""
    );
    all5.current.style.setProperty(
      "background",
      "linear-gradient(to right, #ffffff 2px, #ffffff 2px)",
      ""
    );
    all7.current.style.setProperty(
      "background",
      "linear-gradient(to right, #ffffff 2px, #ffffff 2px)",
      ""
    );
    try {
      document
        .getElementsByClassName("menu-active")[0]
        .style.setProperty("background", "white", "");
    } catch {}
    document
      .getElementsByClassName("menu")[0]
      .style.setProperty("box-shadow", "none", "");
    menu.current.classList.add("closed");
    setTimeout(() => {
      menu.current.style.setProperty("width" , "220px","");
      menu.current.style.setProperty("height" , "100px","");
      menu.current.style.setProperty("overflow" , "hidden","");
    }, 1500);
  }

  function opening() {
    menu.current.style.setProperty("width" , "220px","");
    menu.current.style.setProperty("height" , "auto","");
    menu.current.style.setProperty("overflow" , "","");
  
    for (let i = 1; i < al.length; i++) {
      al[i].current.style.setProperty(
        "background",
        "linear-gradient(to right, #ffffff 2px, #ffffff 2px)"
      );
      al[i].current.style.setProperty("color", "#333");
    }
    document
      .getElementsByClassName("newest")[0]
      .style.setProperty("color", "red");
    document
      .getElementsByClassName("newest")[1]
      .style.setProperty("color", "red");
    document
      .getElementsByClassName("newest")[2]
      .style.setProperty("color", "red");
    all3.current.style.setProperty("color", "#666666", "");
    all5.current.style.setProperty("color", "#666666", "");
    all7.current.style.setProperty("color", "#666666", "");
    all3.current.style.setProperty(
      "background",
      "linear-gradient(to right, #dddddd 2px, #ffffff 2px)",
      ""
    );
    all5.current.style.setProperty(
      "background",
      "linear-gradient(to right, #dddddd 2px, #ffffff 2px)",
      ""
    );
    all7.current.style.setProperty(
      "background",
      "linear-gradient(to right, #dddddd 2px, #ffffff 2px)",
      ""
    );
    try {
      document
        .getElementsByClassName("menu-active")[0]
        .style.setProperty("color", "white");
      document
        .getElementsByClassName("menu-active")[0]
        .style.setProperty("background", "#157efb", "");
    } catch {}
    setTimeout(function () {
      document
        .getElementsByClassName("menu")[0]
        .style.setProperty(
          "box-shadow",
          "1px 1px 20px 1px rgba(0, 0, 0, 1)",
          ""
        );
    }, 800);
    menu.current.classList.remove("closed");
  }

  useEffect(() => {
    closing();
    menu.current.style.setProperty("--count", 9 + "", "");
    al.forEach((e, i) => {
      e.current.style.setProperty("--delay-in", (0.1 * i) / 1.5 + "s", "");
      e.current.style.setProperty(
        "--delay-out",
        (0.1 * (9 - i)) / 1.5 + "s",
        ""
      );
    });
    firebase
      .firestore()
      .collection("Courses")
      .orderBy("order", "desc")
      .get()
      .then((r) => {
        set_new_course({ ...r.docs[0].data() });
        firebase
          .firestore()
          .collection("products")
          .orderBy("order", "desc")
          .get()
          .then((r) => {
            set_new_product({ ...r.docs[0].data() });
            firebase
              .firestore()
              .collection("play-ground")
              .orderBy("order", "desc")
              .get()
              .then((r) => {
                set_new_playground({ ...r.docs[0].data() });
              });
          });
      });
  }, []);

  return (
    <nav className="mobile" ref={nav}>
      <div
        className="classynav"
        style={{ backgroundImage: "url(" + bookmark + ")" }}
      >
        <div id="cn-d">
          <div className="menu closed" ref={menu} id="nav">
            <header
              data-group="0"
              ref={all}
              className="n-h"
              style={{ "--top": "0px" }}
              onClick={clicked}
            >
              Menu <span>x</span>
            </header>
            <ol>
              <li className="menu-item">
                <NavLink
                  ref={all1}
                  data-group="0"
                  className="mm fixx noshadow white"
                  exact={true}
                  to="/"
                  style={{ "--top": "52px" }}
                  activeClassName="menu-active"
                  onClick={clicked}
                >
                  Home
                </NavLink>
              </li>
              <li className="menu-item">
                <NavLink
                  ref={all2}
                  data-group="0"
                  to="/courses"
                  exact={true}
                  activeClassName="menu-active"
                  className="mm fixx noshadow"
                  style={{ "--top": "104px" }}
                  onClick={clicked}
                >
                  Courses
                </NavLink>
                <ol className="sub-menu">
                  <li className="menu-item">
                    <NavLink
                      ref={all3}
                      data-group="1"
                      id="klkl"
                      activeClassName="menu-active"
                      to={"/courses/" + new_course.collection}
                      exact={true}
                      className="notalowed mm mrdn noshadow"
                      style={{ "--top": "141px" }}
                      onClick={clicked}
                    >
                      <span style={{ transition: "1s" }} className="newest">
                        New
                      </span>{" "}
                      {new_course.name}
                    </NavLink>
                  </li>
                </ol>
              </li>
              <li className="menu-item">
                <NavLink
                  ref={all4}
                  data-group="1"
                  exact={true}
                  activeClassName="menu-active"
                  to="/play-ground"
                  className="mm fixx noshadow"
                  style={{ "--top": "180.2px" }}
                  onClick={clicked}
                >
                  Play Ground
                </NavLink>
                <ol className="sub-menu">
                  <li className="menu-item">
                    <NavLink
                      ref={all5}
                      data-group="1"
                      exact={true}
                      activeClassName="menu-active"
                      to={"/play-ground/" + new_playground.order}
                      className="notalowed mm mrdn noshadow"
                      style={{ "--top": "232.2px" }}
                      onClick={clicked}
                    >
                      <span style={{ transition: "1s" }} className="newest">
                        New
                      </span>{" "}
                      {new_playground.title}
                    </NavLink>
                  </li>
                </ol>
              </li>
              <li className="menu-item">
                <NavLink
                  ref={all6}
                  data-group="2"
                  exact={true}
                  to="/products"
                  activeClassName="menu-active"
                  className="mm fixx noshadow"
                  style={{ "--top": "256.4px" }}
                  onClick={clicked}
                >
                  Products
                </NavLink>
                <ol className="sub-menu">
                  <li className="menu-item">
                    <NavLink
                      ref={all7}
                      data-group="2"
                      activeClassName="menu-active"
                      exact={true}
                      to={"/products/" + new_product.order}
                      className="notalowed mm mrdn noshadow"
                      style={{ "--top": "308.4px" }}
                      onClick={clicked}
                    >
                      <span style={{ transition: "1s" }} className="newest">
                        New
                      </span>{" "}
                      {new_product.title}
                    </NavLink>
                  </li>
                </ol>
              </li>
              <li className="menu-item">
                <NavLink
                  ref={all8}
                  to="/contact"
                  exact={true}
                  data-group="2"
                  activeClassName="menu-active"
                  className="mm mmm fixx noshadow"
                  style={{ "--top": "347.6px" }}
                  onClick={clicked}
                >
                  Contact
                </NavLink>
              </li>
            </ol>
            <footer>
              <button onClick={(e) => clicked(e)} aria-label="Toggle menu">
                Toggle
              </button>
            </footer>
          </div>
        </div>
      </div>
    </nav>
  );
}
