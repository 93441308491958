import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class Items extends Component {
  render() {
    return (
      <li>
        <NavLink
          exact={true}
          activeClassName="active"
          to={this.props.head.path}
        >
          {this.props.head.text}
        </NavLink>
      </li>
    );
  }
}
