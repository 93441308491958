import React from "react";
import "../../../style/components/face.css";
import { Link, useHistory } from "react-router-dom";

export default function Face() {
  const history = useHistory();
  return (
    <div id="welcome_div">
      <div id="nameanime">
        <div>
          <div id="tttitle">
            <span className="bbblock"></span>
            <h1>
              Kodo Yousif<span></span>
            </h1>
          </div>
          <div id="rrrole">
            <div className="bbblock"></div>
            <p>Software E.</p>
          </div>
        </div>
      </div>
      <div
        id="con_info"
        onClick={() => {
          history.push("/contact");
        }}
      >
        <Link to="/contact" id="a3a">
          Contact ME
        </Link>
      </div>
    </div>
  );
}
