import React, { Component } from "react";
import "../../../style/components/Laptop.css";
import { Link } from "react-router-dom";
import Items from "./Items";

export default class Laptop extends Component {
  render() {
    const items = [
      { path: "/", text: "Home" },
      { path: "/courses", text: "Courses" },
      { path: "/play-ground", text: "Play Ground" },
      { path: "/products", text: "Products" },
      { path: "/contact", text: "Contact Me" },
    ];
    return (
      <nav id="nnn-pc">
        <Link id="kodo-pc" to="/">
          Kodo Yousif
        </Link>
        <div id="zyada-pc">
          <div className="custm-nav-kkk">
            <ul className="shadow-kkk">
              {items.map((head) => (
                <Items key={head.text} head={head} />
              ))}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
