import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import firebase from "../../database/firebase";
import Present from "../container/Present";
import "../../style/components/playep.css";
export default function ShowC() {
  let { order } = useParams();
  const [alli, set_all] = useState({});
  document.title = alli.title;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    order = parseInt(order);
    firebase
      .firestore()
      .collection("products")
      .orderBy("order")
      .get()
      .then((r) => {
        let all = r.docs.map((r) => {
          return { ...r.data() };
        });
        return all;
      })
      .then((r) => {
        for (let g = 0; g < r.length; g++) {
          if (order === r[g].order) {
            set_all({ des: r[g].des, ep: r[g].link, title: r[g].title });
            break;
          }
        }
      });
  }, [order]);
  return (
    <Present>
      <center>
        <iframe
          id="filming"
          title="course"
          src={alli.ep}
          frameBorder="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        ></iframe>
        <h4>{alli.title}</h4>
        <h6>{alli.des}</h6>
      </center>
    </Present>
  );
}
