import "../../style/components/Footer.css";
export default function Footer() {
  return (
    <div className="container-fluid" id="c-f">
      <div className="row text-center" id="fsocial">
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://github.com/kodo-yousif"
            id="yakamm"
          >
            <i className="fab fa-github-alt"></i>
          </a>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://twitter.com/kodo_koye"
            id="dwamm"
          >
            <i className="fab fa-twitter"></i>
          </a>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.facebook.com/koko.like99/"
            id="seyamm"
          >
            <i className="fab fa-facebook-f"></i>
          </a>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.instagram.com/kodo._.k/"
            id="chwaramm"
          >
            <i className="fab fa-instagram"></i>
          </a>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.youtube.com/channel/UCCTct_csO37wOSSOmM-Rlrw?view_as=subscriber"
            id="penjamm"
          >
            <i className="fab fa-youtube"></i>
          </a>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.snapchat.com/add/kodok.99"
            id="shashamm"
          >
            <i className="fab fa-snapchat-ghost"></i>
          </a>
        </div>
      </div>
      <div className="skills">
        <hr className="hr-text" data-content="©️ Copyright 2021" />
      </div>
    </div>
  );
}
