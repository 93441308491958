import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import firebase from "../../database/firebase";
import Present from "../container/Present";
import "../../style/components/playep.css";

export default function ShowC() {
  let { order } = useParams();
  const [alli, set_all] = useState({});
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    order = parseInt(order);
    firebase
      .firestore()
      .collection("play-ground")
      .orderBy("order")
      .get()
      .then((r) => {
        let all = r.docs.map((r) => {
          return { ...r.data() };
        });
        return all;
      })
      .then((r) => {
        for (let g = 0; g < r.length; g++) {
          if (order === r[g].order) {
            set_all(r[g]);
            break;
          }
        }
      });
  }, [order]);
  document.title = alli.title;
  return (
    <Present hh="full">
      <center>
        <h3 style={{ marginTop: "10px" }}>{alli.title}</h3>
        <h5>{alli.des}</h5>
        <button style={{ margin: "20px" }}>
          <a rel="noreferrer" target="_blank" href={alli.link}>
            Go to
          </a>
        </button>
        <img style={{ width: "100%" }} src={alli.image} alt="wena"></img>
      </center>
    </Present>
  );
}
