import React, { Component } from "react";
import Laptop from "./laptop/Laptop";
import Mobile from "./Mobile";
export default class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = { windowWidth: window.innerWidth };
  }

  componentDidMount() {
    window.addEventListener("resize", (e) => {
      this.setState({ windowWidth: window.innerWidth });
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.windowWidth > 995 ? <Laptop /> : <Mobile />}
      </React.Fragment>
    );
  }
}
