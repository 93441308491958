import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import firebase from "../../database/firebase";
import Glass from "../container/Glass";
export default function ShowC() {
  const { name } = useParams();
  let [ep, set_ep] = useState([]);
  useEffect(() => {
    document.title = name;
    firebase
      .firestore()
      .collection(name)
      .orderBy("order")
      .get()
      .then((r) => {
        let all = r.docs.map((r) => {
          return { ...r.data() };
        });
        set_ep(all);
      });
  }, []);
  return (
    <Glass>
      {ep.map((r, i) => {
        return (
          <div key={i}>
            <Link to={"/courses/" + name + "/" + r.order}>
              <center>
                <img src={r.image} alt="wena" />
                <br />
                <span>
                  {r.title} #{r.order}
                </span>
              </center>
            </Link>
          </div>
        );
      })}
    </Glass>
  );
}
