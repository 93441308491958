import { useState, useEffect } from "react";
import "../../style/components/contact.css";
import shape from "../../asset/shape.png";
import location from "../../asset/location.png";
import emaili from "../../asset/email.png";
import phonei from "../../asset/phone.png";
import wind from "../../asset/wind.png";
import firebase from "../../database/firebase";
export default function Contact() {
  const [name, set_name] = useState("");
  const [email, set_email] = useState("");
  const [phone, set_phone] = useState("");
  const [text, set_text] = useState("");
  function submiting(e) {
    e.preventDefault();
    firebase
      .firestore()
      .collection("emails")
      .add({ name: name, email: email, phone: phone, text: text })
      .then(() => {
        set_name("");
        set_phone("");
        set_text("");
        set_email("");
      });
  }
  useEffect(() => {
    document.title = "Contact me";
  }, []);
  return (
    <div className="contactf-c">
      <span className="big-circle"></span>
      <img src={shape} className="square" alt="me" />
      <div className="form">
        <div className="contact-info">
          <h3 className="title">Let's get in touch 😀</h3>
          <p className="text">
            HEY YOU !!
            <br /> Send me your Feedback or contact me via email or anonymously.
          </p>
          <div>
            <div className="information">
              <img src={location} className="icon" alt="location-img" />
              <p>Kudrdistan , koysinjaq , Zanko</p>
            </div>
            <div className="information">
              <img src={emaili} className="icon" alt="email-img" />
              <p>kodo.00257248@gmail.com</p>
            </div>
            <div
              className="information"
              onClick={() => {
                window.location.href = "tel:+964-750-755-6391";
              }}
            >
              <img src={phonei} className="icon" alt="phone-img" />
              <p>
                <a href="tel:+964-750-755-6391">+964-750-755-63-91</a>
              </p>
            </div>
          </div>
          <img
            src={wind}
            alt="sarxosh"
            id="sarxosh"
            onClick={() => {
              alert("Dont Touch me 😠\nI also won't read 90% of anonymous.");
            }}
          ></img>
          <div className="social-media">
            <p>Connect with me :</p>
            <div className="social-icons">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/koko.like99/"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://twitter.com/kodo_koye"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/kodo._.k/"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.snapchat.com/add/kodok.99"
              >
                <i className="fab fa-snapchat-ghost"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <span className="circle one"></span>
          <span className="circle two"></span>
          <form
            onSubmit={(e) => submiting(e)}
            id="sform"
            action="/email"
            autoComplete="off"
            method="POST"
          >
            <h3 className="title">Contact me</h3>
            <div className="input-container">
              <input
                type="text"
                placeholder="User Name"
                name="name"
                required
                value={name}
                onChange={(e) => set_name(e.target.value)}
                className="input"
              />
            </div>
            <div className="input-container">
              <input
                value={email}
                onChange={(e) => set_email(e.target.value)}
                placeholder="Email"
                type="email"
                name="email"
                required
                className="input"
              />
            </div>
            <div className="input-container">
              <input
                placeholder="Phone (Optional)"
                type="number"
                id="phone_number"
                name="phone"
                value={phone}
                onChange={(e) => set_phone(e.target.value)}
                className="input"
              />
            </div>
            <div className="input-container textarea">
              <textarea
                placeholder="Message"
                name="message"
                value={text}
                required
                onChange={(e) => set_text(e.target.value)}
                className="input"
              ></textarea>
            </div>
            <input type="submit" value="Send" className="btn" />
          </form>
        </div>
      </div>
    </div>
  );
}
