import "../../style/components/present.css";
export default function Present({ hh, children }) {
  return (
    <div
      style={{
        height: hh === "full" ? "calc(92vh - 60px)" : "auto",
        top: hh === "full" ? "calc(4vh + 60px)" : "50vh",
        transformOrigin: hh === "full" ? "top" : "center",
        transform: hh === "full" ? null : "translate(-50%,-50%)",
        width: hh === "full" ? "92vw" : "auto",
        left: hh === "full" ? "4vw" : "50vw",
      }}
      id="present"
    >
      {children}
    </div>
  );
}
