import React from "react";
import "../../style/components/background.css";
import back from "../../asset/background.jpg";
export default function background({ children }) {
  return (
    <div
      style={{ background: "url(" + back + ")" }}
      className="super_background"
    >
      {children}
    </div>
  );
}
